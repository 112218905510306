<template>
  <div class="background: #F6F7FB;">
    <Van-Navbar :showLeftarrow="true" :title="lang.route_accountInformation">
    </Van-Navbar>
    <div class="operation">
      <van-cell-group>
        <van-cell
          v-for="(item, index) in operationList"
          :key="index"
          :value="
            item.value == null || item.value == undefined || item.value == ''
              ? '--'
              : item.value
          "
          :title="lang[item.type]"
        />
      </van-cell-group>
    </div>
  </div>
</template>
  
  <script>
import { reactive, toRefs, ref } from "vue";
import Common from "@/library/Common";
import { useRouter } from "vue-router";
import VanNavbar from "@/components/common/van-navbar";
// 声明一个全局变量 _this
let _this = this;
export default {
  name: "ClassEvaluation",
  components: {
    VanNavbar,
  },
  mounted() {
    //   赋值 : _this是 this的引用
    _this = this;
  },
  setup() {
    const common = Common;
    const router = useRouter();
    let state = reactive({
      _this: null,
      common: common,
      lang: {
        route_accountInformation: "",
        account_number: "",
        password: "",
        cn_name: "",
        en_name: "",
      },
      operationList: [
        {
          name: "账号",
          type: "account_number",
          value: "",
        },
        {
          name: "中文名",
          type: "cn_name",
          value: "",
        },
        {
          name: "英文名",
          type: "en_name",
          value: "",
        },
        // {
        //   name: "密码",
        //   type: "password",
        //   value: "",
        // },
      ],
      fileList: [{ url: common.getRes("touxiang_default.png"), isImage: true }],
    });
    common.initLanguage(state.lang);
    const getUserInfo = () => {
      let teacherInfo = JSON.parse(localStorage.getItem("teacherInfo"));
      state.operationList.map((item) => {
        switch (item.type) {
          case "account_number":
            item["value"] = localStorage.getItem("phone_number");
            break;
          case "cn_name":
            item["value"] = teacherInfo["cn_name"];
            break;
          case "en_name":
            item["value"] = teacherInfo["en_name"];
            break;
          case "password":
            item["value"] = localStorage.getItem("password");
            break;
        }
      });
    };
    getUserInfo();
    return {
      ...toRefs(state),
      common,
      getUserInfo,
    };
  },
};
</script>
  
<style lang="less" scoped>
.operation {
  // margin: 0 20px;
  /deep/.van-cell {
    padding: 20px 30px;
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 42px;
  }
  /deep/.van-cell__title {
    text-align: left;
    color: #000000;
  }
  /deep/.van-cell__value {
    color: #999999;
  }
}
</style>